@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind components;
@tailwind utilities;

* {
  font-family: "Kanit", sans-serif;
  /* font-family: "Noto Sans Thai", sans-serif; */
}

.slick-track {
  margin-left: 0 !important;
}

::placeholder {
  font-family: "Kanit", sans-serif;
}

.ant-tag {
  font-family: "Kanit", sans-serif !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  z-index: -1 !important;
}

:root {
  --primary-color: #506e83;
  --light-primary-color: #abcae1;
  --dark-primary-color: #214c6a;

  --secondary-color: #AE8A37;
  --light-secondary-color: #CDB57F;
  --dark-secondary-color: #887240;

  --gold-color: #daa222;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
span {
  margin: 0;
  padding: 0;
}

/* remove margin */
.rm {
  margin: 0 !important;
}
/* remove padding */
.rp {
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Kanit", sans-serif;
  /* font-family: "Noto Sans Thai", sans-serif; */
}
.course-list {
  opacity: 1;
}
.course-list:hover {
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.025);
}

.limited-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* Limit to 3 lines */
  max-width: 340px;
}

.btn-outline {
  opacity: 1;
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 600;
  padding: 10px 35px;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 0;
  background-color: transparent;
  border: 1px solid var(--primary-color);
}
.btn-outline:hover {
  opacity: 0.5;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-primary {
  background-color: var(--primary-color);
}
.bg-secondary {
  background-color: var(--secondary-color);
}

.text-primary {
  color: var(--primary-color) !important;
}
.text-secondary {
  color: var(--secondary-color);
}
.text-dark-error {
  color: #ba2d0f;
}
.text-error {
  color: #ff2e37;
}
.text-warm {
  color: #ff881b;
}
.text-warning {
  color: #ffd828;
}
.text-success {
  color: #47d477;
}

/* ----- CUSTOM ANT DESIGN -------------------------------------- */

/* datepicker selecttion */
/* .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 100px !important;
}
.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 100px !important;
} */
